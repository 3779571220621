<template>
<div class="page">
    <div class="d-flex justify-content-center">

    <navbar></navbar>
    </div>
    
      <div class="terms">

        <div class="termsTitle d-flex justify-content-center align-items-center">
          <img class="img img-responsive navbar-brand" height="100"
                                                src="../assets/logo.png">
          <h1>Legal Standard Terms</h1>
        </div>
        <div class="termsContent">
          <object data="./terms.pdf" type="application/pdf" >
        </object>
        </div>
        
      </div>


      

</div>
</template>
<script>
import Navbar from '../components/Navbar.vue'
import NavBar from "../components/Navbar.vue"
export default {
  components: { Navbar },
    
}
</script>
<style scoped>
.page {
  width: 100vw;
  height: fit-content;
  min-height: 100vh;
  background-image: linear-gradient(120deg, #193c7091 0%, #002060 150%),
    url("../assets/dash2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  
}
object{
  width: 100%;
  height: 100%;
}
.terms{
  background-color: #f0f0f0;
  width: 80%;
  height: 80vh;
  border-radius: 10px;
  margin: 3rem auto;
  
}
.terms .termsTitle{
  height: 15%;
}
.terms .termsContent{
  height: 70%;
  width:80%;
  margin: auto;
  background-color: white;
  overflow-y: scroll;
}
.terms .termsAgree{
  height: 15%;
}
#termsAndConditions{
  width: 100vw;
  height: 90vh;
}
</style>